import React from "react";
import hangars from '../static/hangars.jpg';
import businessSpaces from '../static/business-spaces.jpg';

const AboutServices = () => {
 
  return (
    <>
      <section className="about-wrap">
        <article className="about-item-article">
          <h2>Hangar (DIA)</h2>
          <figure className="about-item">
            <img className="about-description-img" src={businessSpaces} alt="business spaces" />
            <p className="about-description">
    Ensure your airport hangar shines with our professional cleaning service. We specialize in maintaining the pristine condition of hangars, offering thorough cleaning solutions tailored to your needs. From sweeping and mopping to disinfecting high-touch surfaces, our expert team ensures a spotless environment. With our attention to detail and commitment to excellence, you can trust us to keep your hangar clean and inviting for visitors and staff alike.
            </p>
            <ul>
              <li>Cleaning industrial surfaces</li>
              <li>Restrooms and bathrooms</li>
              <li>specialiizing in large sufrace area cleaning and conditioning</li>
            </ul>
          </figure>
        </article>

        <article className="about-item-article">
          <h2>Business spaces at DIA</h2>
          <figure className="about-item">
            <img className="about-description-img" src={hangars} alt="hangars" />
            <p className="about-description">
    Maintaining a clean work environment is paramount for the success of your business. A cluttered or unclean space can tarnish your business's reputation among both customers and employees. At SCJS, we specialize in janitorial services that safeguard your business and preserve its image. With over 10 years of experience in the commercial cleaning industry, we are committed to delivering an elevated level of cleanliness.
            </p>

          <div className="about-services-list">
            <ul>
              <li>Ticket counters</li>
              <li>Lobby</li>
              <li>Kiosks</li>
              <li>Informational monitors
                <ul>
                  <li>FIDs, GIDs, BIDs</li>
                </ul>
              </li>
            </ul>

            <ul>
              <li>Vacuum</li>
              <li>Dust cleanup
                <ul>
                  <li>Desks and office spaces</li>
                  <li>Electronics</li>
                  <li>Hard accessible spots</li>
                </ul>
              </li>
            </ul>

            <ul>
              <li>Carpet Cleaning
                <ul>
                  <li>Carpet washing</li>
                  <li>Carpet vacuuming</li>
                  <li>Steaming</li>
                  <li>Deep cleaning</li>
                </ul>
              </li>
            </ul>

            <ul>
              <li>Bathroom cleaning
                <ul>
                  <li>Touchless sanitization</li>
                  <li>Floor waxing</li>
                  <li>Polishing</li>
                  <li>Supply replenishment</li>
                </ul>
              </li>
            </ul>
          </div>
          </figure>
        </article>
      </section>

      <section className="about-wrap">
          <article className="about-item-article">
            <h2>Restaurants</h2>
            <figure className="about-item">
              <ul>
                <li>General dining area</li>
                <li>Kitchens</li>
                <li>Office spaces</li>
                <li>Auxiliary spaces</li>
                <li>Storage spaces</li>
              </ul>
            </figure>
          </article>

        <article className="about-item-article">
          <h2>Emergency Services</h2>
          <figure className="about-item">

          <div className="about-services-list">
            <ul>
              <li>Water flooding damage cleanup</li>
              <li>Biological hazards emergency cleanup</li>
              <li>On-call emergency response </li>
              <li>24/7 service</li>
            </ul>
          </div>
          </figure>
        </article>
      </section>
    </>
  );
};

export default AboutServices;
