import React from "react";
import logo from '../static/scjs_logo.svg';
import facebook from '../static/icons/facebook.svg';

const Header = () => {
 
  return (
    <section className="header">
      <div className="header-wrap">
        <div className="logo-wrap">
          <header className="header-container">
            <img className="logo" src={logo} alt="logo" />
            <h1>Janitorial Cleaning Services</h1>
          </header>

          <div className="header-links">
            <a href="https://www.facebook.com/p/super-cleaning-janatorial-service-100064139251497/" target="_blank" rel="noreferrer nofollow">
              <img src={facebook} alt="facebook" />
            </a>
          </div>
        </div>
      </div>

      <article className="intro-wrap">
        <div className="intro-section">
          <div className="intro">
            <div className="intro-item">
              <p className="intro-item-title">Ready to work!<br />24/7 service</p>
              <p>Experience a pristine and healthful workspace courtesy of SCJS.</p>
            </div>
            <div className="intro-item">
              <p className="intro-item-title">Our team will respond within 24 hours.</p>
              <p>A SSCJS representative will reach out to you to discuss further details.</p>
            </div>
            <div className="intro-item">
              <p className="intro-item-title">Tailor a plan to suit your specific requirements.</p>
              <p>Get a Free Quote by providing details about the cleaning services you require.</p>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Header;
