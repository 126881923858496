import './App.css';
import './scss/style.scss';
import Header from './components/Header';
import AboutServices from './components/AboutServices';
import Footer from './components/Footer';
import AboutCompany from './components/AboutCompany';

function App() {
  return (
    <div className="App">
      <Header />
      <AboutCompany />
      <AboutServices />
      <Footer />
    </div>
  );
}

export default App;
