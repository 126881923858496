import React from "react";

const Footer = () => {

  return (
    <div className="footer-wrap">
      <p>
      6063 north enesanada court , Aurora, CO, United States, Colorado
      </p>
      <a href="tel:+05890000111">0-589-0000111</a>
    </div>
  );
};

export default Footer;
