import React from "react";
import den from '../static/icons/den.jpg';
import lufthansa from '../static/icons/lufthansa.jpg';
import frontier from '../static/icons/frontier.jpg';

const AboutCompany = () => {
 
  return (
    <section className="about-company-wrap">
      <article className="about-company-article">
        <h2>About</h2>
        <ul>
          <li>
            History
            <ul>
              <li>
Super Cleaners Janitorial Services was founded in 2005, locally owned and operated in Denver, Colorado. Over the past 24 years we serviced public and private organizations at the Denver International Airport.
              </li>
            </ul>
          </li>
          <li>
            Mission
            <ul>
              <li>
Supporting critical infrastructure with the clean and sustainable environment.
              </li>
              <li>
We utilize environmentally-friendly chemicals and supplies that meet or exceed the DIA-approved standards and regulations.
              </li>
            </ul>
          </li>
          <li>
            Staff
            <ul>
              <li>
Locally owned and operated our crew consists of full time employees and on-call professionals with extensive experience in the industry.
              </li>
            </ul>
          </li>
        </ul>
      </article>
      <h2>Our Clients</h2>
      <div className="about-logo">
        <img src={lufthansa} alt="lufthansa" />
        <img src={den} alt="den" />
        <img src={frontier} alt="frontier" />
      </div>
    </section>
  );
};

export default AboutCompany;
